/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { Fragment } from 'react';

import { MetaComponent as SourceMetaComponent } from 'SourceComponent/Meta/Meta.component';

/**
 * Page Meta data
 * @class Meta
 * @namespace Poprawa/Component/Meta/Component */
export class MetaComponent extends SourceMetaComponent {
    renderMeta() {
        const { metadata, ogmetadata } = this.props;

        return [
            <Fragment key="meta-tags">
                { this.renderTitle() }
                { this.renderCanonical() }
                { metadata.map((tag) => {
                    const {
                        name,
                        property = null,
                        content,
                    } = tag;

                    return (
                        <meta
                          key={ name || property }
                          name={ name }
                          content={ content }
                        />
                    );
                }) }
                { ogmetadata.map((tag) => <meta key={ tag.name || tag.property } { ...tag } />) }
            </Fragment>,
        ];
    }
}

export default MetaComponent;
