/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { SET_BLIK_CODE } from './Blik.action';

/** @namespace Poprawa/Store/Blik/Reducer/getInitialState */
export const getInitialState = () => ({
    blikCode: '',
});

/** @namespace Poprawa/Store/Blik/Reducer/BlikReducer */
export const BlikReducer = (state = getInitialState(), action) => {
    const { type } = action;

    switch (type) {
    case SET_BLIK_CODE:
        const { code } = action;

        return { ...state, blikCode: code };
    default:
        return state;
    }
};

export default BlikReducer;
