/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

export const SET_BLIK_CODE = 'SET_BLIK_CODE';

/** @namespace Poprawa/Store/Blik/Action/setBlikCode */
export const setBlikCode = (code) => ({
    type: SET_BLIK_CODE,
    code,
});
