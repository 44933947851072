/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */
import {
    SET_IS_COMPANY,
    UPDATE_DELIVERY_POINTS,
    UPDATE_EMAIL,
    UPDATE_EMAIL_AVAILABLE,
    UPDATE_SELECTED_DELIVERY_POINTS,
    UPDATE_SHIPPING_FIELDS,
} from './Checkout.action';

/** @namespace Poprawa/Store/Checkout/Reducer/getInitialState */
export const getInitialState = () => ({
    shippingFields: {},
    email: '',
    isEmailAvailable: true,
    isCompany: false,
    isPaymentFormValid: false,
    paymentMethod: '',
    deliveryPoints: [],
    selectedLocker: null,
});

/** @namespace Poprawa/Store/Checkout/Reducer/CheckoutReducer */
export const CheckoutReducer = (state = getInitialState(), action) => {
    switch (action.type) {
    case UPDATE_SHIPPING_FIELDS:
        const { shippingFields } = action;

        return {
            ...state,
            shippingFields,
        };

    case UPDATE_EMAIL:
        const { email } = action;

        return {
            ...state,
            email,
        };

    case UPDATE_EMAIL_AVAILABLE:
        const { isEmailAvailable } = action;

        return {
            ...state,
            isEmailAvailable,
        };

    case SET_IS_COMPANY:
        const { isCompany } = action;

        return {
            ...state,
            isCompany,
        };

    case UPDATE_DELIVERY_POINTS:
        const { deliveryPoints } = action;

        return {
            ...state,
            deliveryPoints,
        };

    case UPDATE_SELECTED_DELIVERY_POINTS:
        const { selectedLocker } = action;

        return {
            ...state,
            selectedLocker,
        };

    default:
        return state;
    }
};

export default CheckoutReducer;
