/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
    RouterContainer as SourceRouterContainer,
} from 'SourceComponent/Router/Router.container';
import { waitForPriorityLoad } from 'Util/Request/LowPriorityLoad';

export const LabelDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Label/Label.dispatcher'
);

export {
    mapStateToProps,
};

/** @namespace Poprawa/Component/Router/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    fetchLabels: async () => LabelDispatcher.then(
        ({ default: dispatcher }) => dispatcher.handleData(dispatch),
    ),
});

/** @namespace Poprawa/Component/Router/Container */
export class RouterContainer extends SourceRouterContainer {
    static propTypes = {
        ...super.propTypes,
        fetchLabels: PropTypes.func.isRequired,
    };

    initializeApplication() {
        const { init, fetchLabels } = this.props;

        init();

        waitForPriorityLoad().then(
            /** @namespace Poprawa/Component/Router/Container/RouterContainer/initializeApplication/waitForPriorityLoad/then */
            () => {
                fetchLabels();
            },
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RouterContainer);
