/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { getStaticReducers as sourceGetStaticReducers } from 'SourceStore';
import BlikReducer from 'Store/Blik/Blik.reducer';
import LabelReducer from 'Store/Label/Label.reducer';

export * from 'SourceStore';

/** @namespace Poprawa/Store/Index/getStaticReducers */
export const getStaticReducers = () => ({
    ...sourceGetStaticReducers(),
    LabelReducer,
    BlikReducer,
});
