/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { SET_LABELS } from './Label.action';

/** @namespace Poprawa/Store/Label/Reducer/getInitialState */
export const getInitialState = () => ({
    labels: [],
});

/** @namespace Poprawa/Store/Label/Reducer/LabelReducer */
export const LabelReducer = (state = getInitialState(), action) => {
    const { type } = action;

    if (type === SET_LABELS) {
        const { labels } = action;

        return {
            labels,
        };
    }

    return state;
};

export default LabelReducer;
