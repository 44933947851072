/* eslint-disable react/prop-types */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-arrow-functions-in-class */
/* eslint-disable react/destructuring-assignment */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer,
} from 'SourceComponent/Meta/Meta.container';

/** @namespace Poprawa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    'og:title': state.MetaReducer['og:title'],
    'og:description': state.MetaReducer['og:description'],
    'og:image': state.MetaReducer['og:image'],
    'og:url': state.MetaReducer['og:url'],
    'og:type': state.MetaReducer['og:type'],
    locale: state.ConfigReducer.locale,
});

/** @namespace Poprawa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static defaultProps = {
        ...super.defaultProps,
        'og:title': '',
        'og:description': '',
        'og:image': '',
        'og:url': '',
        'fb:app_id': '',
        'og:type': 'website',
    };

    containerProps() {
        const {
            canonical_url,
            default_title,
            title,
            title_prefix,
            title_suffix,
        } = this.props;

        return {
            metadata: this._getMetadata(),
            canonical_url,
            default_title,
            title,
            title_prefix,
            title_suffix,
            ogmetadata: this._getOGMetadata(),
        };
    }

    _getOGMetadata = () => {
        const meta = {
            'og:type': this.props['og:type'],
            'og:url': this.props['og:url'],
            'og:title': this.props['og:title'],
            'og:description': this.props['og:description'],
            'og:image': this.props['og:image'],
            'og:image:width': '1200',
            'og:image:height': '630',
            'og:locale': this.props.locale || 'pl_PL',
            'twitter:card': 'summary_large_image',
            'twitter:title': this.props['og:title'],
            'twitter:description': this.props['og:description'],
            'twitter:image': this.props['og:image'],
        };

        return this._generateMetaFromMetadata(meta, 'property');
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
